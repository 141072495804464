import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CiShare2, CiLink } from "react-icons/ci";
import { GoReport, GoPlus } from "react-icons/go";
import { useParams } from "react-router-dom";
import { IoChevronBackOutline } from "react-icons/io5";
import { get_event, event_sign, get_event_signs } from "../api/events";
import AboutInitiative from "./AboutInitiative";
import Chat from "./Chat";
import "../css/home.css";
import "../css/about-initiative.css";
import { IoMdClose } from "react-icons/io";
import { FaFacebookF } from "react-icons/fa";
import MenuItem from "@mui/material/MenuItem";
import { FacebookShareButton } from "react-share";
import { StyledMenu } from "../helpers";
function Initiative() {
	const { eventid } = useParams();
	const navigate = useNavigate();
	const { isAuth } = useSelector((state) => state.auth);
	const [showMap, setShowMap] = useState(true);
	const [aboutOpen, setAboutOpen] = useState(true);
	const [messagesOpen, setMessagesOpen] = useState(false);
	const [event, setEvent] = useState(null);
	const [shareOpen, setShareOpen] = useState(false);
	const [participants, setParticipants] = useState(0);
	const [modalImage, setModalImage] = useState(null);
	const [anchorEl, setAnchorEl] = useState(null);
	const [isParticipantHere, setIsParticipaneHere] = useState(false);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setShareOpen(true);
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setShareOpen(false);
		setAnchorEl(null);
	};
	const handleEmailClick = () => {
		const subject = "Saveyourhood - report of inappropriate content";
		const body =
			`I would like to report inappropriate content in Save your hood` +
			` on the event ${event.title} - to this link https://projectkapa.gr/initiative/${event.id}\n\n` +
			`[Please describe what you find inappropriate]`;

		const mailtoLink = `mailto:info@saveyourhood.gr?subject=${encodeURIComponent(
			subject
		)}&body=${encodeURIComponent(body)}`;

		window.location.href = mailtoLink;
	};
	useEffect(() => {
		if (eventid) {
			get_event({ eventid: eventid }).then((response) => {
				setEvent(response.data);
			});
			get_event_signs({ eventid: eventid })
				.then((res) => {
					setParticipants(res.data.event_signs.length);
					if (res.data.user_signs.length > 0)
						setIsParticipaneHere(true);
				})
				.catch((err) => {
					console.log(err);
				});
		}
	}, [eventid]);
	const handleParticipate = async () => {
		try {
			const response = await event_sign({ eventid: eventid });
			if (response.statusText === "Created") {
				get_event_signs({ eventid: eventid })
					.then((res) => {
						setParticipants(res.data.event_signs.length);
						if (res.data.user_signs.length > 0)
							setIsParticipaneHere(true);
					})
					.catch((err) => {
						console.log(err);
					});
			}
		} catch (error) {
			if (error.response.data === "Unauthorized") {
				alert("You need to log in first");
			} else {
				alert(error.response.data.errors[0].msg);
			}
		}
	};

	return (
		<>
			<div className="saveyourhood-layout initiative-layout">
				<div className="actions-selections ">
					<div className="actions-selections-left">
						<button
							className="toggle-button"
							onClick={() => navigate("/")}
							title="Back to home page!"
						>
							<IoChevronBackOutline />
							<span>Back to initiatives</span>
						</button>

						<button
							className={`toggle-button ${
								shareOpen ? "active" : ""
							}`}
							onClick={handleClick}
							title="Share the initiative with your friends!"
						>
							<CiShare2 />
							<span>Share</span>
						</button>
						<StyledMenu
							id="demo-customized-menu"
							MenuListProps={{
								"aria-labelledby": "demo-customized-button",
							}}
							sx={{ zIndex: 2001 }}
							anchorEl={anchorEl}
							open={open}
							onClose={handleClose}
						>
							<MenuItem
								onClick={() => {
									navigator.clipboard.writeText(
										`https://projectkapa.gr/initiative/${eventid}`
									);
									setShareOpen(false);
									setAnchorEl(null);
								}}
								disableRipple
							>
								<>
									<CiLink
										style={{
											width: "2.5em",
											height: "2em",
										}}
									/>
									<span>Copy Link</span>
								</>
							</MenuItem>
							<MenuItem
								disableRipple
								onClick={() => {
									setShareOpen(false);
									setAnchorEl(null);
								}}
							>
								<FacebookShareButton
									url={`https://projectkapa.gr/initiative/${eventid}`}
								>
									<FaFacebookF
										style={{
											width: "2.5em",
											height: "1em",
										}}
									/>
									<span>Facebook</span>
								</FacebookShareButton>
							</MenuItem>
						</StyledMenu>
						<button
							className="toggle-button"
							onClick={() => handleEmailClick()}
							title="Report inapropriate content"
						>
							<GoReport />
							<span>Report inapropriate content</span>
						</button>
						<>
							{isAuth ? (
								<>
									{isParticipantHere ? (
										<button
											// disabled
											className="toggle-button"
											onClick={() =>
												alert(
													"You have already joined this event"
												)
											}
											title="Join the initiative"
										>
											<GoPlus color="gray" />
											<span style={{ color: "gray" }}>
												Join
											</span>
										</button>
									) : (
										<button
											className="toggle-button"
											onClick={handleParticipate}
											title="Join the initiative"
										>
											<GoPlus />
											<span>Join</span>
										</button>
									)}
								</>
							) : (
								<button
									// disabled
									className="toggle-button"
									onClick={() =>
										alert("You need to login first")
									}
									title="Join the initiative"
								>
									<GoPlus color="gray" />
									<span style={{ color: "gray" }}>Join</span>
								</button>
							)}
						</>
					</div>
					<div className="actions-selections-right">
						<button
							className={`toggle-button ${
								aboutOpen ? "active" : ""
							}`}
							onClick={() => {
								setAboutOpen(true);
								setMessagesOpen(false);
							}}
							title="Information about the initiative"
						>
							About the initiative
						</button>
						<>
							{isAuth ? (
								<>
									{isParticipantHere ? (
										<button
											className={`toggle-button ${
												messagesOpen ? "active" : ""
											}`}
											onClick={() => {
												setMessagesOpen(true);
												setAboutOpen(false);
											}}
											title="Check out the initiave's chat"
										>
											Event's Chat
										</button>
									) : (
										<button
											className="toggle-button"
											onClick={() =>
												alert(
													"You need to join the event first!"
												)
											}
											title="Check out the initiave's chat"
										>
											<span style={{ color: "gray" }}>
												Event's Chat
											</span>
										</button>
									)}
								</>
							) : (
								<button
									className="toggle-button"
									onClick={() =>
										alert("You need to login first")
									}
									title="Check out the initiave's chat"
								>
									<span style={{ color: "gray" }}>
										Event's Chat
									</span>
								</button>
							)}
						</>
					</div>
				</div>
				<div className="saveyourhood-map-layout">
					<div>
						<div
							className="initiative-fullscreen"
							style={{
								width: "100%",
							}}
						>
							{aboutOpen && event && (
								<AboutInitiative
									event={event}
									participants={participants}
									setModalImage={setModalImage}
									isAuth={isAuth}
								/>
							)}
							{messagesOpen && <Chat eventid={eventid} />}
						</div>
					</div>
				</div>
			</div>
			{modalImage && (
				<div className="custom-modal-overlay">
					<div
						className="custom-modal"
						style={{ background: "#c7f9cc" }}
					>
						<span
							className="close"
							style={{
								display: "flex",
								alignItems: "flex-end",
								justifyContent: "flex-end",
								cursor: "pointer",
							}}
							onClick={() => setModalImage(null)}
						>
							<IoMdClose />
						</span>
						<img
							src={modalImage}
							alt="Modal"
							style={{ width: "100%" }}
						/>
					</div>
				</div>
			)}
		</>
	);
}

export default Initiative;
