import React, { useEffect, useState } from "react";
import "../css/event-modal.css";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { TextField, FormControlLabel, Switch, FormGroup } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { create_event } from "../api/events";
import Loading from "../components/Loading";

const MenuProps = {
	style: {
		maxHeight: 260,
		width: 250,
		zIndex: 2000,
	},
};
const tags = [
	"Littered Area",
	"Large Objects",
	"Affected fauna",
	"Hazardous waste",
];
function AddNewEvent({
	setAddNewEvent,
	newPosition,
	setNewPosition,
	setGetAgain,
}) {
	const [error, setError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const [newEvent, setNewEvent] = useState({
		eventPosition: newPosition,
		eventDesc: "",
		eventTitle: "",
		eventType: null,
		isSticks: false,
		isGloves: false,
		isBags: false,
		cleanupItems: [],
		eventArea: null,
		startDate: new Date(),
		files: [],
	});
	const body = document.querySelector("body");
	body.style.overflow = "hidden";
	const handleSubmit = async () => {
		try {
			body.style.overflow = "";
			setIsLoading(true);
			const formData = new FormData();
			formData.append("lat", newEvent.eventPosition.lat);
			formData.append("lng", newEvent.eventPosition.lng);
			formData.append("desc", newEvent.eventDesc);
			formData.append("title", newEvent.eventTitle);
			formData.append("type", newEvent.eventType);
			formData.append("isSticks", newEvent.isSticks);
			formData.append("isGloves", newEvent.isGloves);
			formData.append("isBags", newEvent.isBags);
			formData.append("cleanupItems", newEvent.cleanupItems);
			formData.append("eventArea", newEvent.eventArea);
			formData.append("startDate", newEvent.startDate);

			for (const key of Object.keys(newEvent.files)) {
				formData.append("image", newEvent.files[key]);
			}
			const response = await create_event(formData);

			if (response.status === 201) {
				setAddNewEvent(false);
				setNewPosition(null);
				setGetAgain(true);
				setIsLoading(false);
				body.style.overflow = "";
				alert(response.data.message);
			}
		} catch (error) {
			console.log(error.response);
			setIsLoading(false);
			body.style.overflow = "";

			if (error.response) {
				//from middleware multer
				if (error.response.data.message) {
					setError(error.response.data.message);
					//from the controller
				} else if (error.response.data.error) {
					setError(error.response.data.error);
					//from the validators
				} else {
					setError(error.response.data.errors[0].msg);
				}
			}
		}
	};
	return (
		<div className="custom-modal-overlay">
			{isLoading ? (
				<Loading />
			) : (
				<div className="custom-modal">
					<h2>Create a new event</h2>
					<form>
						<div className="div">
							<TextField
								InputProps={{
									readOnly: true,
								}}
								id="standard-basic"
								label="Coordinates"
								variant="standard"
								sx={{ minWidth: "100%" }}
								value={newEvent.eventPosition}
							/>
						</div>
						<div className="div">
							<TextField
								id="standard-basic"
								label="Event Title"
								variant="standard"
								sx={{ minWidth: "100%" }}
								value={newEvent.eventTitle}
								onChange={(e) => {
									setNewEvent({
										...newEvent,
										eventTitle: e.target.value,
									});
								}}
							/>
						</div>
						<div className="div">
							<TextField
								id="standard-multiline-static"
								label="Description"
								multiline
								rows={4}
								value={newEvent.eventDesc}
								onChange={(e) => {
									setNewEvent({
										...newEvent,
										eventDesc: e.target.value,
									});
								}}
								sx={{ minWidth: "100%" }}
							/>
						</div>
						<div style={{ marginBottom: "5px" }}>
							<span>Date & time: </span>
							<DatePicker
								showIcon
								selected={newEvent.startDate}
								showPopperArrow={false}
								showTimeSelect
								onChange={(date) =>
									setNewEvent({
										...newEvent,
										startDate: date,
									})
								}
								dateFormat="d MMMM, yyyy HH:mm"
								timeFormat="HH:mm"
							/>
						</div>
						<div className="div">
							<FormControl
								sx={{ m: 1, minWidth: "100%" }}
								size="small"
								style={{ margin: 0 }}
							>
								<InputLabel id="demo-simple-select-label">
									Type of event
								</InputLabel>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={newEvent.eventType}
									label="Type of event"
									onChange={(e) => {
										setNewEvent({
											...newEvent,
											eventType: e.target.value,
										});
									}}
									renderValue={(selected) => selected}
									MenuProps={MenuProps}
								>
									<MenuItem value={"Clean-up"}>
										Clean-up
									</MenuItem>
									<MenuItem
										value={
											"Educational environmental seminar"
										}
									>
										Educational environmental seminar
									</MenuItem>
									<MenuItem value={"Crisis Response"}>
										Crisis Response
									</MenuItem>
									<MenuItem value={"Wildfire SAR Mission"}>
										Wildfire SAR Mission
									</MenuItem>
									<MenuItem value={"Other"}>
										Other (provide more info on description)
									</MenuItem>
								</Select>
							</FormControl>
						</div>
						<div className="div">
							<FormControl
								sx={{ m: 1, minWidth: "100%" }}
								size="small"
								style={{ margin: 0 }}
							>
								<InputLabel id="demo-simple-select-label">
									Type of area
								</InputLabel>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={newEvent.eventArea}
									label="Type of area"
									onChange={(e) => {
										setNewEvent({
											...newEvent,
											eventArea: e.target.value,
										});
									}}
									renderValue={(selected) => selected}
									MenuProps={MenuProps}
								>
									<MenuItem value={"Coast/Beach"}>
										Coast / Beach
									</MenuItem>
									<MenuItem value={"Urban/City"}>
										Urban / City
									</MenuItem>
									<MenuItem value={"Park"}>Park</MenuItem>
									<MenuItem value={"Mountain/Hill"}>
										Mountain / Hill
									</MenuItem>
									<MenuItem value={"Lake/River"}>
										Lake / River
									</MenuItem>
									<MenuItem value={"Forest/Woodland"}>
										Forest / Woodland
									</MenuItem>
								</Select>
							</FormControl>
						</div>
						{newEvent.eventType === "Clean-up" && (
							<>
								<div className="div">
									<FormControl
										sx={{ m: 1, minWidth: "100%" }}
										size="small"
										style={{ margin: 0 }}
									>
										<InputLabel id="demo-multiple-checkbox-label">
											Clean-up items
										</InputLabel>
										<Select
											labelId="demo-multiple-checkbox-label"
											id="demo-multiple-checkbox"
											multiple
											value={newEvent.cleanupItems}
											onChange={(event) => {
												const { value } = event.target;
												setNewEvent({
													...newEvent,
													cleanupItems:
														typeof value ===
														"string"
															? value.split(",")
															: value,
												});
											}}
											input={
												<OutlinedInput label="Clean-up items" />
											}
											renderValue={(selected) =>
												selected.join(", ")
											}
											MenuProps={MenuProps}
										>
											{tags.map((tag) => (
												<MenuItem
													key={tag}
													value={tag}
													style={{ zIndex: 9999 }}
												>
													<Checkbox
														checked={
															newEvent.cleanupItems.indexOf(
																tag
															) > -1
														}
													/>
													<ListItemText
														primary={tag}
													/>
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</div>
								<div className="div">
									<FormGroup
										column
										style={{ maxWidth: "fit-content" }}
									>
										<FormControlLabel
											control={
												<Switch
													color="primary"
													checked={newEvent.isSticks}
													onChange={(event) => {
														setNewEvent({
															...newEvent,
															isSticks:
																event.target
																	.checked,
														});
													}}
												/>
											}
											label="Pick-up sticks will be provided"
											style={{ margin: 0 }}
										/>
										<FormControlLabel
											control={
												<Switch
													color="primary"
													checked={newEvent.isGloves}
													onChange={(event) => {
														setNewEvent({
															...newEvent,
															isGloves:
																event.target
																	.checked,
														});
													}}
												/>
											}
											label="Gloves will be provided"
											style={{ margin: 0 }}
										/>
										<FormControlLabel
											control={
												<Switch
													color="primary"
													checked={newEvent.isBags}
													onChange={(event) => {
														setNewEvent({
															...newEvent,
															isBags: event.target
																.checked,
														});
													}}
												/>
											}
											label="Bin bags will be provided"
											style={{ margin: 0 }}
										/>
									</FormGroup>
									{/* </FormControl> */}
								</div>
							</>
						)}

						{/* {type === "green" && (
				
					)} */}
						<div className="div">
							<span>Upload image: </span>
							<input
								type="file"
								multiple
								accept="image/*"
								onChange={(e) => {
									setNewEvent({
										...newEvent,
										files: e.target.files,
									});
								}}
							/>
						</div>
						<div style={{ color: "red", margin: "10px 0" }}>
							{error}
						</div>
						<div className="modal-buttons">
							<button
								className="modal-submit-btn"
								type="button"
								onClick={handleSubmit}
							>
								Submit
							</button>
							<button
								className="modal-cancel-btn"
								type="button"
								onClick={() => {
									body.style.overflow = "";
									setAddNewEvent(false);
									setNewPosition(null);
								}}
							>
								Cancel
							</button>
						</div>
					</form>
				</div>
			)}
		</div>
	);
}

export default AddNewEvent;
