import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import {
	CiBag1,
	CiCircleInfo,
	CiCalendar,
	CiLocationOn,
	CiHashtag,
} from "react-icons/ci";
import { GiWaterDivinerStick } from "react-icons/gi";
import { GiGloves } from "react-icons/gi";
import { IoLocationSharp } from "react-icons/io5";
import { FaUsers } from "react-icons/fa";
import person from "../assets/person.png";

function AboutInitiative({ event, participants, setModalImage, isAuth }) {
	console.log(event);
	return (
		<>
			<div className="map-layout--content ">
				<div className="detail-view">
					<div className="tabs">
						<nav className="tabs-header">
							<div className="item">
								<div
									aria-current="page"
									className="tab-item-header"
								>
									{event.event_type === "Crisis Response" && (
										<IoLocationSharp color="CA8224" />
									)}
									{event.event_type === "Clean-up" && (
										<IoLocationSharp color="green" />
									)}
									{event.event_type === "Other" && (
										<IoLocationSharp color="797979" />
									)}
									{event.event_type ===
										"Wildfire SAR Mission" && (
										<IoLocationSharp color="red" />
									)}
									{event.event_type ===
										"Educational environmental seminar" && (
										<IoLocationSharp color="DarkViolet" />
									)}

									{"  "}
									{event.title}
								</div>
								<span className="bar"></span>
							</div>
						</nav>
						<div className="tabs-content">
							<div className="operation-details">
								<div className="profile">
									<div className="profile-image">
										<img
											src={person}
											alt="User profile pic"
										></img>
									</div>
									<div className="profile-info">
										<p>Event creator</p>
										<h3>{event.username}</h3>
									</div>
								</div>
								<div className="content-info">
									<ul className="details-info">
										{event.isSticks !== null && (
											<li>
												<span
													className="icon"
													title="Will pickup sticks be provided"
												>
													<GiWaterDivinerStick />
												</span>
												<div className="column">
													<p>
														{" "}
														{event.isSticks
															? `Sticks will be provided`
															: `Sticks will not be provided`}
													</p>
												</div>
											</li>
										)}

										{event.isGloves !== null && (
											<li>
												<span
													className="icon"
													title="Will gloves be provided"
												>
													<GiGloves />
												</span>
												<div className="column">
													<p>
														{" "}
														{event.isGloves
															? `Gloves will be provided`
															: `Gloves will not be provided`}{" "}
													</p>
												</div>
											</li>
										)}

										{event.isBags !== null && (
											<li>
												<span
													className="icon"
													title="Will bin bags be provided"
												>
													<CiBag1 />
												</span>
												<div className="column">
													<p>
														{" "}
														{event.isBags
															? `Bin bags will be provided`
															: `Bin bags will not be provided`}{" "}
													</p>
												</div>
											</li>
										)}

										<li>
											<span
												className="icon"
												title="Where is the place"
											>
												<CiLocationOn />
											</span>
											<div
												className="column"
												onClick={() => {
													window.open(
														"https://maps.google.com?q=" +
															event.lat +
															"," +
															event.lng,
														"_blank"
													);
												}}
											>
												<p>
													{event.canonical_place.replace(
														"Municipality of ",
														""
													)}
												</p>
											</div>
										</li>
										{event.cleanupItems &&
											event.cleanupItems.length > 0 && (
												<li>
													<span
														className="icon"
														title="Tags of the initiative"
													>
														<CiHashtag />
													</span>
													<div className="column">
														<p>
															{event.cleanupItems.replace(
																",",
																", "
															)}
														</p>
													</div>
												</li>
											)}

										<li>
											<span
												className="icon"
												title="What type of place is it"
											>
												<CiCircleInfo />
											</span>
											<div className="column">
												<p>{event.eventArea}</p>
											</div>
										</li>
										<li>
											<span
												className="icon"
												title="When does it start"
											>
												<CiCalendar />
											</span>
											<div className="column">
												<p>
													{event.startDate
														? format(
																new Date(
																	event.startDate
																),
																"d MMMM, yyyy HH:mm"
														  )
														: format(
																new Date(
																	event.createdAt
																),
																"d MMMM, yyyy HH:mm"
														  )}
												</p>
											</div>
										</li>
									</ul>
									<div className="right"></div>
								</div>
								<div className="desc-wrapper">
									<div className="placeholder">
										<CiCircleInfo /> Description
									</div>
									{event.desc}
								</div>
								<div className="desc-wrapper">
									<div className="placeholder">
										<FaUsers /> Participants
									</div>
									{isAuth
										? participants
										: "You need to login first to view the participants"}
								</div>
								<div className="desc-wrapper">
									Photo(s)
									<div>
										{event.img_name.map((img) => {
											return (
												<img
													src={`https://storage.googleapis.com/saveyourhood_bucket/${event.id}/${img}`}
													alt={img}
													style={{
														width: "100px",
														cursor: "pointer",
													}}
													onClick={() =>
														setModalImage(
															`https://storage.googleapis.com/saveyourhood_bucket/${event.id}/${img}`
														)
													}
												/>
											);
										})}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default AboutInitiative;
