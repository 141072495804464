const Comment = ({ key, msg }) => {
	// dd/mm/yyyy, hh:mm:ss
	function formatDateFromTimestamp(timestamp) {
		const date = new Date(parseInt(timestamp));
		const options = {
			weekday: "short",
			day: "numeric",
			month: "short",
			year: "numeric",
			hour: "numeric",
			minute: "numeric",
			second: "numeric",
			hour12: false,
		};
		return date.toLocaleString(undefined, options);
	}
	return (
		<div key={key} className="comment">
			<div className="comment-image-container">
				{/* <img src="/user-icon.png" /> */}
			</div>
			<div className="comment-right-part">
				<div className="comment-content">
					<div className="comment-author">{msg.username}</div>
					<div className="comment-created">
						{formatDateFromTimestamp(msg.__createdtime__)}
					</div>
				</div>
				<div className="comment-text">{msg.message}</div>
			</div>
		</div>
	);
};

export default Comment;
