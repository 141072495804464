import React, { useEffect, useState } from "react";
import { onLoginPost } from "../api/auth";
import { useDispatch } from "react-redux";
import { authenticateUser } from "../redux/slices/authSlice";
import Login from "../components/AuthPage/Login";
import Bubbles from "../components/AuthPage/Bubbles";
import "../css/LoginSignup.css";
import { useLocation } from "react-router-dom";
function LoginPage() {
	const location = useLocation();
	const [userLoginInput, setUserLoginInput] = useState({
		email: "",
		password: "",
	});
	const [success, setSuccess] = useState(
		location.state !== null ? true : false
	);
	const [error, setError] = useState(false);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const onLoginChange = (e) => {
		setUserLoginInput({
			...userLoginInput,
			[e.target.name]: e.target.value,
		});
	};

	const dispatch = useDispatch();
	const onLogin = async (e) => {
		e.preventDefault();
		try {
			const response = await onLoginPost(userLoginInput);
			dispatch(authenticateUser());
			localStorage.setItem("isAuth", "true");
		} catch (error) {
			setError(error.response.data.errors[0].msg);
			setSuccess(false);
		}
	};

	return (
		<div className="container">
			<div className="form-container">
				<Login
					onLogin={onLogin}
					onLoginChange={onLoginChange}
					userLoginInput={userLoginInput}
					error={error}
					success={success}
				/>
			</div>
			<Bubbles />
		</div>
	);
}

export default LoginPage;
