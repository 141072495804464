import L from "leaflet";
import { Marker, useMap, useMapEvents } from "react-leaflet";
import { Icon, divIcon } from "leaflet";
import { styled, alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";

export function CustomMarker({ index, position, customMarkerIcon, color }) {
	const map = useMap();

	return (
		<Marker
			key={index}
			position={position}
			icon={customMarkerIcon(color)}
			eventHandlers={{
				click: () => {
					map.flyTo(position, 15, {
						duration: 0.5,
					});
				},
			}}
		/>
	);
}
export function LocationMarker({ newPosition, setNewPosition }) {
	useMapEvents({
		click(e) {
			setNewPosition(e.latlng);
		},
	});
	return newPosition === null ? null : (
		<Marker position={newPosition}></Marker>
	);
}
export const TileLayer1 = L.tileLayer(
	"https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
	{
		attribution:
			'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
	}
);

export const TileLayer2 = L.tileLayer(
	"https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png",
	{
		attribution:
			'&copy; <a href="Esri &mdash">Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community</a> contributors',
	}
);

export const customMarkerIcon = (color) => {
	return new L.Icon({
		iconUrl: `https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-${color}.png`,
		iconSize: [25, 41],
		iconAnchor: [12, 41],
		popupAnchor: [1, -34],
		tooltipAnchor: [16, -28],
		shadowSize: [41, 41],
	});
};

export const redCluster = (cluster) => {
	return divIcon({
		html: `<div><span>${cluster.getChildCount()}</span></div>`,
		className: "red-cluster-icon",
		iconSize: [40, 40],
	});
};

export const crisisCluster = (cluster) => {
	return divIcon({
		html: `<div><span>${cluster.getChildCount()}</span></div>`,
		className: "orange-cluster-icon",
		iconSize: [40, 40],
	});
};
export const otherCluster = (cluster) => {
	return divIcon({
		html: `<div><span>${cluster.getChildCount()}</span></div>`,
		className: "grey-cluster-icon",
		iconSize: [40, 40],
	});
};
export const seminarsCluster = (cluster) => {
	return divIcon({
		html: `<div><span>${cluster.getChildCount()}</span></div>`,
		className: "violet-cluster-icon",
		iconSize: [40, 40],
	});
};
export const greenCluster = (cluster) => {
	return divIcon({
		html: `<div><span>${cluster.getChildCount()}</span></div>`,
		className: "green-cluster-icon",
		iconSize: [40, 40],
	});
};

export const StyledMenu = styled((props) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: "bottom",
			horizontal: "right",
		}}
		transformOrigin={{
			vertical: "top",
			horizontal: "right",
		}}
		{...props}
	/>
))(({ theme }) => ({
	"& .MuiPaper-root": {
		borderRadius: 6,
		marginTop: theme.spacing(1),
		minWidth: 180,
		zIndex: 2001,
		color:
			theme.palette.mode === "light"
				? "rgb(55, 65, 81)"
				: theme.palette.grey[300],
		boxShadow:
			"rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
		"& .MuiMenu-list": {
			padding: "4px 0",
		},
		"& .MuiMenuItem-root": {
			"& .MuiSvgIcon-root": {
				fontSize: 18,
				color: theme.palette.text.secondary,
				marginRight: theme.spacing(1.5),
				zIndex: 2001,
			},
			"&:active": {
				backgroundColor: alpha(
					theme.palette.primary.main,
					theme.palette.action.selectedOpacity
				),
				zIndex: 2001,
			},
		},
	},
}));
