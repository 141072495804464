import { IoMdClose } from "react-icons/io";
import React, { useEffect, useState } from "react";
import "../css/dashboard.css";
import { dashboardInfo } from "../api/auth";
import { approve_event, decline_event } from "../api/events";
import AdminApproval from "../components/UserPage/AdminApproval";
import UserStats from "../components/UserPage/UserStats";
function Dashboard() {
	const [pending, setPending] = useState([]);
	const [approved, setApproved] = useState([]);
	const [isAdmin, setIsAdmin] = useState(false);
	const [modalImage, setModalImage] = useState(null);

	useEffect(() => {
		async function fetchData() {
			const request = await dashboardInfo();
			setIsAdmin(request.data.admin);

			if (request.data.admin) {
				setPending(request.data.events);
			} else {
				const filt_pend = request.data.events.filter(
					(event) => event.approved !== true
				);
				const filt_app = request.data.events.filter(
					(event) => event.approved !== false
				);
				setPending(filt_pend);
				setApproved(filt_app);
			}
		}

		fetchData();
	}, []);
	const handleApprove = (eventId) => {
		approve_event({ eventid: eventId })
			.then((res) => {
				setPending((prevPending) =>
					prevPending.filter((event) => event.id !== eventId)
				);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleDecline = (eventId) => {
		decline_event({ eventid: eventId }).then((res) => {
			setPending((prevPending) =>
				prevPending.filter((event) => event.id !== eventId)
			);
		});
	};

	return (
		<>
			{isAdmin ? (
				<>
					{pending.length > 0 && (
						<div>
							{pending.map((event) => (
								<AdminApproval
									key={event.id}
									event={event}
									onApprove={handleApprove}
									onDecline={handleDecline}
									setModalImage={setModalImage}
								/>
							))}
						</div>
					)}
				</>
			) : (
				<>
					<UserStats approved={approved} pending={pending} />
				</>
			)}

			{modalImage && (
				<div className="custom-modal-overlay">
					<div
						className="custom-modal"
						style={{ background: "#c7f9cc" }}
					>
						<span
							className="close"
							style={{
								display: "flex",
								alignItems: "flex-end",
								justifyContent: "flex-end",
								cursor: "pointer",
							}}
							onClick={() => setModalImage(null)}
						>
							<IoMdClose />
						</span>
						<img
							src={modalImage}
							alt="Modal"
							style={{ width: "100%" }}
						/>
					</div>
				</div>
			)}
		</>
	);
}

export default Dashboard;
