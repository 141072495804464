import axios from "axios";
axios.defaults.withCredentials = true;

export async function onRegistration(registrationData) {
	return await axios.post(
		process.env.NODE_ENV === "development"
			? "http://localhost:5000/users/register"
			: "/server/users/register",
		registrationData
	);
}

export async function onLoginPost(loginData) {
	return await axios.post(
		process.env.NODE_ENV === "development"
			? "http://localhost:5000/users/login"
			: "/server/users/login",
		loginData
	);
}

export async function onLogout() {
	return await axios.get(
		process.env.NODE_ENV === "development"
			? "http://localhost:5000/users/logout"
			: "/server/users/logout"
	);
}

export async function fetchProtectedInfo() {
	return await axios.get(
		process.env.NODE_ENV === "development"
			? "http://localhost:5000/users/protected"
			: "/server/users/protected"
	);
}

export async function is_admin() {
	return await axios.get(
		process.env.NODE_ENV === "development"
			? "http://localhost:5000/calendar/can_create"
			: "/server/calendar/can_create"
	);
}

export async function onVerify(otp) {
	return await axios.post(
		process.env.NODE_ENV === "development"
			? "http://localhost:5000/users/verify"
			: "/server/users/verify",
		otp
	);
}

export async function dashboardInfo() {
	return await axios.get(
		process.env.NODE_ENV === "development"
			? "http://localhost:5000/users/dashboard"
			: "/server/users/dashboard"
	);
}
