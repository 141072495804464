import React from "react";
import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import { IoLocationSharp } from "react-icons/io5";

function CardItem({ marker, index }) {
	return (
		<Link className="map-item" to={`/initiative/${marker.id}`} key={index}>
			<div className="map-item-image">
				{marker.event_type === "Clean-up" ? (
					<IoLocationSharp
						color="green"
						style={{
							width: "2em",
							height: "2em",
						}}
					/>
				) : marker.event_type ===
				  "Educational environmental seminar" ? (
					<IoLocationSharp
						color="DarkViolet"
						style={{
							width: "2em",
							height: "2em",
						}}
					/>
				) : marker.event_type === "Crisis Response" ? (
					<IoLocationSharp
						color="CA8224"
						style={{
							width: "2em",
							height: "2em",
						}}
					/>
				) : marker.event_type === "Wildfire SAR Mission" ? (
					<IoLocationSharp
						color="red"
						style={{
							width: "2em",
							height: "2em",
						}}
					/>
				) : (
					<IoLocationSharp
						color="797979"
						style={{
							width: "2em",
							height: "2em",
						}}
					/>
				)}
			</div>
			<div className="event-middle-items">
				<p
					style={{
						fontSize: "20px",
						fontWeight: "bold",
					}}
				>
					{marker.startDate}
				</p>
				<p
					style={{
						fontSize: "15px",
						fontWeight: "bold",
					}}
				>
					{marker.title}
				</p>
				<p
					style={{
						fontSize: "15px",
					}}
				>
					{marker.canonical_place
						.replace("Municipality of ", "")
						.replace(" Municipality", "")}
				</p>

				<p
					style={{
						fontSize: "15px",
						fontStyle: "italic",
						color: "blue",
					}}
				>
					{marker.event_type === "Clean-up"
						? "Clean-up"
						: marker.event_type ===
						  "Educational environmental seminar"
						? "Seminar"
						: marker.event_type === "Crisis Response"
						? "Crisis response"
						: marker.event_type === "Wildfire SAR Mission"
						? "Wildfire SAR"
						: "Other"}
				</p>
			</div>
			<div className="map-item-arrow">
				<IoIosArrowForward
					style={{
						width: "1.5em",
						height: "1.5em",
					}}
				/>
			</div>
		</Link>
	);
}

export default CardItem;
