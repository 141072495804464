import React from "react";

function Bubbles() {
	return (
		<div className="bubbles-background">
			<span className="bubble"></span>
			<span className="bubble"></span>
			<span className="bubble"></span>
			<span className="bubble"></span>
			<span className="bubble"></span>
			<span className="bubble"></span>
			<span className="bubble"></span>
			<span className="bubble"></span>
			<span className="bubble"></span>
			<span className="bubble"></span>
			<span className="bubble"></span>
			<span className="bubble"></span>
		</div>
	);
}

export default Bubbles;
