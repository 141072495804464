import React, { useEffect, useState } from "react";
import { IoLocationSharp } from "react-icons/io5";
import { MdOutlineAddLocationAlt } from "react-icons/md";
import { useSelector } from "react-redux";
import { BsFilterLeft } from "react-icons/bs";
import TextField from "@mui/material/TextField";
// import { useInfiniteQuery } from "react-query";
import Loading from "../components/Loading";
import { IoIosHelpCircleOutline } from "react-icons/io";
import { get_events } from "../api/events";
import AddNewEvent from "./AddNewEvent";
import "../css/home.css";
import { format } from "date-fns";
import MapLayout from "../components/MapLayout";
import CardItem from "../components/CardItem";
import HowToDialog from "../components/HowToDialog";
function Home() {
	const { isAuth } = useSelector((state) => state.auth);
	const [showSeminars, setShowSeminars] = useState(true);
	const [showCleanups, setShowCleanups] = useState(true);
	const [showCrisis, setShowCrisis] = useState(true);
	const [showWildfires, setShowWildfires] = useState(true);
	const [showOther, setShowOther] = useState(true);
	const [addNewEvent, setAddNewEvent] = useState(false);
	const [howToOpen, setHowToOpen] = useState(false);
	const [markers, setMarkers] = useState(null);
	const [getAgain, setGetAgain] = useState(false);
	const [newPosition, setNewPosition] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");

	useEffect(() => {
		get_events_f();
	}, [getAgain]);

	const get_events_f = () => {
		setIsLoading(true);
		get_events()
			.then((res) => {
				const formattedEvents = res.data.events.map((event) => {
					return {
						...event,
						startDate: format(
							new Date(event.startDate),
							"EEEE d, MMMM, yyyy, HH:mm"
						),
					};
				});
				setMarkers(formattedEvents);
				setIsLoading(false);
				setGetAgain(false);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const filteredMarkers =
		markers &&
		markers.filter((marker) => {
			const canonicalPlace = marker.canonical_place.toLowerCase();
			const title = marker.title.toLowerCase();
			const startDate = marker.startDate.toLowerCase();

			// Check if any of the fields contain the search query
			return (
				canonicalPlace.includes(searchQuery.toLowerCase()) ||
				startDate.includes(searchQuery.toLowerCase()) ||
				title.includes(searchQuery.toLowerCase())
			);
		});

	return (
		<>
			<div className="saveyourhood-layout">
				<div className="actions-selections">
					<div className="actions-selections-left">
						<button
							className={`toggle-button ${
								showCleanups ? "active" : ""
							}`}
							onClick={() => setShowCleanups(!showCleanups)}
							title="Clean-ups"
						>
							<IoLocationSharp color="green" />
							<span>Clean-ups</span>
						</button>
						<button
							className={`toggle-button ${
								showSeminars ? "active" : ""
							}`}
							onClick={() => setShowSeminars(!showSeminars)}
							title="Seminars"
						>
							<IoLocationSharp color="DarkViolet" />
							<span>Seminars</span>
						</button>
						<button
							className={`toggle-button ${
								showCrisis ? "active" : ""
							}`}
							onClick={() => setShowCrisis(!showCrisis)}
							title="Crisis Responses"
						>
							<IoLocationSharp color="CA8224" />
							<span>Crisis Responses</span>
						</button>
						<button
							className={`toggle-button ${
								showWildfires ? "active" : ""
							}`}
							onClick={() => setShowWildfires(!showWildfires)}
							title="Wildfire SAR Missions"
						>
							<IoLocationSharp color="red" />
							<span>Wildfire SAR</span>
						</button>
						<button
							className={`toggle-button ${
								showOther ? "active" : ""
							}`}
							onClick={() => setShowOther(!showOther)}
							title="Others"
						>
							<IoLocationSharp color="797979" />
							<span>Others</span>
						</button>
						{isAuth ? (
							<>
								<button
									className={`toggle-button ${
										addNewEvent ? "active" : ""
									}`}
									onClick={() => {
										setAddNewEvent(true);
									}}
									title="Create a new event"
								>
									<MdOutlineAddLocationAlt color="black" />
									<span>Create an event</span>
								</button>
							</>
						) : (
							<>
								<button
									className={`toggle-button ${
										addNewEvent ? "active" : ""
									}`}
									onClick={() => {
										alert("You need to login first");
									}}
									title="Create a new event"
								>
									<MdOutlineAddLocationAlt color="black" />
									<span style={{ color: "gray" }}>
										Create an event
									</span>
								</button>
							</>
						)}
					</div>
					<div className="actions-selections-right">
						<button
							className="toggle-button"
							onClick={() => setHowToOpen(true)}
							title="How it works"
						>
							<IoIosHelpCircleOutline />
						</button>
						<TextField
							id="basic-search"
							label="Search by date/time, title and location"
							type="search"
							fullWidth
							sx={{ border: "none" }}
							onChange={(e) => setSearchQuery(e.target.value)}
						/>
					</div>
				</div>
				<div className="saveyourhood-map-layout">
					{isLoading ? (
						<div
							style={{
								width: "100%",
								height: "calc(100vh - 170px)",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<Loading />
						</div>
					) : (
						<div>
							<div className="map-layout-left">
								<MapLayout
									filteredMarkers={filteredMarkers}
									showCleanups={showCleanups}
									showSeminars={showSeminars}
									showCrisis={showCrisis}
									showOther={showOther}
									showWildfires={showWildfires}
									addNewEvent={addNewEvent}
									newPosition={newPosition}
									setNewPosition={setNewPosition}
								/>
							</div>
							<div className="map-layout-right">
								<div className="map-items-list">
									{filteredMarkers &&
										filteredMarkers.length > 0 &&
										filteredMarkers.map((marker, index) => {
											if (
												(marker.event_type ===
													"Educational environmental seminar" &&
													showSeminars) ||
												(marker.event_type ===
													"Clean-up" &&
													showCleanups) ||
												(marker.event_type ===
													"Crisis Response" &&
													showCrisis) ||
												(marker.event_type ===
													"Wildfire SAR Mission" &&
													showWildfires) ||
												(marker.event_type ===
													"Other" &&
													showOther)
											) {
												return (
													<CardItem
														marker={marker}
														index={index}
														key={index}
													/>
												);
											}
											return null;
										})}
									{!filteredMarkers ||
										(filteredMarkers.length === 0 && (
											<div>No event found... </div>
										))}
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
			{newPosition && addNewEvent && (
				<AddNewEvent
					setAddNewEvent={setAddNewEvent}
					newPosition={newPosition}
					setNewPosition={setNewPosition}
					setGetAgain={setGetAgain}
				/>
			)}
			{howToOpen && (
				<HowToDialog
					howToOpen={howToOpen}
					setHowToOpen={setHowToOpen}
				/>
			)}
		</>
	);
}

export default Home;
