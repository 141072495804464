import { format } from "date-fns";

function AdminApproval({ event, onApprove, onDecline, setModalImage }) {
	const {
		id,
		event_type,
		username,
		lat,
		lng,
		canonical_place,
		desc,
		title,
		img_name,
		eventArea,
		startDate,
	} = event;

	console.log(event);
	return (
		<div className="pending-card">
			<div className="card-body">
				<div className="info-section">
					<h3>
						Starts:{" "}
						{format(new Date(startDate), "d MMMM, yyyy HH:mm")}
					</h3>
					<h3>Title: {title}</h3>
					<p>Created by: {username}</p>
					<hr />
					<p style={{ fontWeight: "bold" }}>Description:</p>
					<p
						style={{
							whiteSpace: "pre-wrap",
							wordBreak: "break-all",
							wordWrap: "break-word",
						}}
					>
						{desc}
					</p>

					<hr />
					<p>
						<span style={{ fontWeight: "bold" }}>Event Type:</span>{" "}
						{event_type}
					</p>
					{event_type === "Clean-up" && (
						<>
							<p>
								{event.isSticks
									? `Sticks will be provided`
									: `Sticks will not be provided`}
							</p>
							<p>
								{" "}
								{event.isGloves
									? `Gloves will be provided`
									: `Gloves will not be provided`}{" "}
							</p>
							<p>
								{" "}
								{event.isBags
									? `Bin bags will be provided`
									: `Bin bags will not be provided`}{" "}
							</p>
						</>
					)}
					<p>
						<span style={{ fontWeight: "bold" }}>Location:</span>{" "}
						{canonical_place.replace("Municipality of ", "")}
						<span
							className="location-link"
							onClick={() => {
								window.open(
									"https://maps.google.com?q=" +
										lat +
										"," +
										lng,
									"_blank"
								);
							}}
						>
							{" "}
							Show on Map
						</span>
					</p>
					<p>
						<span style={{ fontWeight: "bold" }}>
							Type of area:
						</span>{" "}
						{eventArea}
					</p>
					{event.cleanupItems && event.cleanupItems.length > 0 && (
						<p>
							<span style={{ fontWeight: "bold" }}>
								Clean-up tags:
							</span>{" "}
							{event.cleanupItems.replace(",", ", ")}
						</p>
					)}
					<hr />
				</div>
			</div>
			<div className="image-section">
				{img_name.map((img) => {
					return (
						<img
							src={`https://storage.googleapis.com/saveyourhood_bucket/${event.id}/${img}`}
							alt={img}
							style={{
								width: "100px",
								cursor: "pointer",
							}}
							onClick={() =>
								setModalImage(
									`https://storage.googleapis.com/saveyourhood_bucket/${event.id}/${img}`
								)
							}
						/>
					);
				})}
			</div>
			<div className="card-footer">
				<button onClick={() => onApprove(id)}>Approve</button>
				<button onClick={() => onDecline(id)}>Decline</button>
			</div>
		</div>
	);
}
export default AdminApproval;
