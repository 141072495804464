import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
function HowToDialog({ howToOpen, setHowToOpen }) {
	return (
		<Dialog
			open={howToOpen}
			scroll="paper"
			aria-labelledby="scroll-dialog-title"
			aria-describedby="scroll-dialog-description"
		>
			<DialogTitle id="scroll-dialog-title">
				How the tool works
			</DialogTitle>
			<DialogContent dividers={true}>
				<DialogContentText id="scroll-dialog-description" tabIndex={-1}>
					<ul>
						<li>
							<strong>Registration Process:</strong>
							<ol>
								<li>
									Click on the{" "}
									<Link to="/register" target="_blank">
										Registration Link
									</Link>{" "}
									to start the registration process.
								</li>
								<li>Fill in your information and submit.</li>
								<li>
									Check your email inbox for a verification
									email.
								</li>
								<li>
									Click the verification link provided in the
									email to verify your email address.
								</li>
								<li>
									You will be redirected to the login page.
								</li>
								<li>
									Log in with your registered email and
									password.
								</li>
							</ol>
						</li>

						<li>
							<strong>Exploring Events:</strong>
							<ol>
								<li>
									Upon logging in, you'll land on the home
									page.
								</li>
								<li>
									Events are displayed both as a map view on
									the left side and as a list on the right
									side.
								</li>
								<li>
									Use the filter button on top of the map to
									filter events by type (Clean-ups, Seminars,
									Crisis Responses, Wildfire SAR missions, and
									Others).
								</li>
								<li>
									Utilize the search bar above the list to
									filter events by date, time, location, and
									event title.
								</li>
								<li>
									Click on an event from the list to view
									detailed information such as description,
									participants, etc.
								</li>
								<li>
									Join an event by clicking the "Join" button
									on the top bar.
								</li>
								<li>
									Access the event's chat by clicking the
									"Chat" button.
								</li>
							</ol>
						</li>

						<li>
							<strong>Creating Your Own Event:</strong>
							<ol>
								<li>
									On the Home page, click the "Create an
									Event" button located on top of the map.
								</li>
								<li>
									Select the location for your event on the
									map.
								</li>
								<li>
									A pop-up window will appear, providing
									fields to input event details.
								</li>
								<li>
									Note: The location's coordinates are
									automatically transformed into a canonical
									address, though specific location
									information should be provided in the
									description textbox.
								</li>
								<li>
									After filling in the event details, submit
									the form.
								</li>
								<li>
									Your event will await approval by an
									admin/organizer.
								</li>
								<li>
									Once approved, your event will be displayed
									on the Home page, and other users can join.
								</li>
							</ol>
						</li>

						<li>
							<strong>Dashboard:</strong>
							<ol>
								<li>
									Access the Dashboard page to view your total
									approved events and those pending approval.
								</li>
							</ol>
						</li>
					</ul>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						setHowToOpen(false);
					}}
				>
					Ok
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default HowToDialog;
