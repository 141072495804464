import React from "react";
import user_icon from "../../assets/person.png";
import email_icon from "../../assets/email.png";
import password_icon from "../../assets/password.png";
import logo from "../../assets/logo.png";
import { Link } from "react-router-dom";

function Login({ onLogin, onLoginChange, userLoginInput, error, success }) {
	return (
		<>
			<form onSubmit={(e) => onLogin(e)}>
				<div className="login-wrapper">
					<div className="auth-header">
						<div />
						<div className="text">
							<h2>Sign in</h2>
						</div>
						{/* <img src={logo} alt="saveyourhood-logo" /> */}
						<div />
					</div>
					<div className="auth-inputs">
						<div className="input">
							<img src={email_icon} alt="" />
							<input
								onChange={(e) => onLoginChange(e)}
								type="email"
								className="form-control"
								id="email"
								name="email"
								value={userLoginInput.email}
								placeholder="email"
								required
							/>
						</div>

						<div className="input">
							<img src={password_icon} alt="" />
							<input
								onChange={(e) => onLoginChange(e)}
								type="password"
								value={userLoginInput.password}
								className="form-control"
								id="password"
								name="password"
								placeholder="passwod"
								required
							/>
						</div>
					</div>
					<div style={{ color: "red", margin: "10px 0" }}>
						{error}
					</div>

					{success && (
						<div style={{ color: "green", margin: "10px 0" }}>
							Email sucessfully verified, please log in
						</div>
					)}

					<div className="submit-container">
						<button type="submit" className="submit-btn">
							Sign in
						</button>
					</div>
					<div className="bottom-auth-form">
						<div className="bottom-auth-form-text">
							Don't have an account?
						</div>
						<Link
							className="bottom-auth-form-text-ref"
							to="/register"
						>
							Sign up here
						</Link>
					</div>
				</div>
			</form>
		</>
	);
}

export default Login;
