import axios from "axios";
axios.defaults.withCredentials = true;

export async function create_event(formData) {
	return await axios.post(
		process.env.NODE_ENV === "development"
			? "http://localhost:5000/events/create_event"
			: "/server/events/create_event",
		formData
	);
}

export async function get_events() {
	return await axios.get(
		process.env.NODE_ENV === "development"
			? "http://localhost:5000/events/get_events"
			: "/server/events/get_events"
	);
}

export async function get_event(eventid) {
	return await axios.post(
		process.env.NODE_ENV === "development"
			? "http://localhost:5000/events/get_event"
			: "/server/events/get_event",
		eventid
	);
}

export async function event_sign(signData) {
	return await axios.post(
		process.env.NODE_ENV === "development"
			? "http://localhost:5000/events/event_sign"
			: "/server/events/event_sign",
		signData
	);
}
export async function get_event_signs(eventid) {
	return await axios.post(
		process.env.NODE_ENV === "development"
			? "http://localhost:5000/events/get_event_signs"
			: "/server/events/get_event_signs",
		eventid
	);
}

export async function approve_event(eventid) {
	return await axios.post(
		process.env.NODE_ENV === "development"
			? "http://localhost:5000/events/approve_event"
			: "/server/events/approve_event",
		eventid
	);
}
export async function decline_event(eventid) {
	return await axios.post(
		process.env.NODE_ENV === "development"
			? "http://localhost:5000/events/decline_event"
			: "/server/events/decline_event",
		eventid
	);
}
