// NavigationBar.jsx
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import "../css/NavigationBar.css"; // Import your CSS file
import logo from "../assets/logo.png";
import { useSelector, useDispatch } from "react-redux";
import { fetchProtectedInfo, onLogout } from "../api/auth";
import { unauthenticateUser } from "../redux/slices/authSlice";
function NavigationBar() {
	const { isAuth } = useSelector((state) => state.auth);
	const navRef = useRef();

	const showNavbar = () => {
		navRef.current.classList.toggle("responsive_nav");
	};
	const dispatch = useDispatch();
	const logout = async () => {
		try {
			await onLogout();

			dispatch(unauthenticateUser());
			localStorage.removeItem("isAuth");
		} catch (error) {
			console.log(error.response);
		}
	};
	useEffect(() => {
		const promise = fetchProtectedInfo();
		promise
			.then((response) => {
				if (response.data.info !== "yes") {
					dispatch(unauthenticateUser());
					localStorage.removeItem("isAuth");
				}
			})
			.catch((err) => {
				if (err.message === "Network Error") console.log(err.message);
				else if (err.response.data === "Unauthorized") {
					dispatch(unauthenticateUser());
					localStorage.removeItem("isAuth");
				}
			});
	}, []);

	return (
		<header>
			<div className="nav-left-wrapper">
				<Link to="/">
					<img src={logo} alt="saveyourhood-logo" />
				</Link>
				<Link to="/">
					<h2>Save your hood</h2>
				</Link>
			</div>
			<div className="nav-right-wrapper">
				<nav ref={navRef}>
					<Link to="/">Home</Link>
					{isAuth && <Link to="/dashboard">Dashboard</Link>}
					{!isAuth ? (
						<Link to="/login">Login</Link>
					) : (
						<Link onClick={() => logout()}>Logout</Link>
					)}
					<button
						className="nav-btn nav-close-btn"
						onClick={showNavbar}
					>
						<FaTimes />
					</button>
				</nav>
				<button className="nav-btn" onClick={showNavbar}>
					<FaBars />
				</button>
			</div>
		</header>
	);
}

export default NavigationBar;
