import React, { useEffect, useState } from "react";
import normal from "../assets/normal.png";
import satelite from "../assets/satelite.png";
import {
	CustomMarker,
	LocationMarker,
	TileLayer1,
	TileLayer2,
	customMarkerIcon,
	greenCluster,
	seminarsCluster,
	crisisCluster,
	redCluster,
	otherCluster,
} from "../helpers";
import { MapContainer, TileLayer } from "react-leaflet";
import L from "leaflet";
import MarkerClusterGroup from "@changey/react-leaflet-markercluster";

function MapLayout({
	filteredMarkers,
	showCleanups,
	showSeminars,
	showCrisis,
	showOther,
	showWildfires,
	addNewEvent,
	newPosition,
	setNewPosition,
}) {
	const [map, setMap] = useState(null);
	const [currentLayer, setCurrentLayer] = useState(TileLayer1);
	useEffect(() => {
		if (map) {
			currentLayer.addTo(map);
		}
	}, [map, currentLayer]);

	const changeTileLayer = () => {
		const newLayer = currentLayer === TileLayer1 ? TileLayer2 : TileLayer1;
		map.eachLayer((layer) => {
			if (layer instanceof L.TileLayer) {
				map.removeLayer(layer);
			}
		});
		newLayer.addTo(map);
		setCurrentLayer(newLayer);
	};
	return (
		<MapContainer
			center={[37.9842, 23.7281]}
			zoom={13}
			scrollWheelZoom={true}
			zoomControl={false}
			ref={setMap}
		>
			<TileLayer
				attribution={currentLayer.options.attribution}
				url={currentLayer._url}
			/>
			<button
				onClick={changeTileLayer}
				style={{
					backgroundImage: `url(${
						currentLayer === TileLayer1 ? satelite : normal
					})`,
				}}
				className="layer-button"
			/>
			{filteredMarkers && (
				<>
					{showCleanups && (
						<MarkerClusterGroup
							showCoverageOnHover={false}
							iconCreateFunction={greenCluster}
						>
							{filteredMarkers
								.filter(
									(marker) => marker.event_type === "Clean-up"
								)
								.map((data, index) => (
									<CustomMarker
										key={index}
										position={[data.lat, data.lng]}
										customMarkerIcon={customMarkerIcon}
										color="green"
									/>
								))}
						</MarkerClusterGroup>
					)}
					{showSeminars && (
						<MarkerClusterGroup
							showCoverageOnHover={false}
							iconCreateFunction={seminarsCluster}
						>
							{filteredMarkers
								.filter(
									(marker) =>
										marker.event_type ===
										"Educational environmental seminar"
								)
								.map((data, index) => (
									<CustomMarker
										key={index}
										position={[data.lat, data.lng]}
										customMarkerIcon={customMarkerIcon}
										color="violet"
									/>
								))}
						</MarkerClusterGroup>
					)}
					{showCrisis && (
						<MarkerClusterGroup
							showCoverageOnHover={false}
							iconCreateFunction={crisisCluster}
						>
							{filteredMarkers
								.filter(
									(marker) =>
										marker.event_type === "Crisis Response"
								)
								.map((data, index) => (
									<CustomMarker
										key={index}
										position={[data.lat, data.lng]}
										customMarkerIcon={customMarkerIcon}
										color="orange"
									/>
								))}
						</MarkerClusterGroup>
					)}
					{showWildfires && (
						<MarkerClusterGroup
							showCoverageOnHover={false}
							iconCreateFunction={redCluster}
						>
							{filteredMarkers
								.filter(
									(marker) =>
										marker.event_type ===
										"Wildfire SAR Mission"
								)
								.map((data, index) => (
									<CustomMarker
										key={index}
										position={[data.lat, data.lng]}
										customMarkerIcon={customMarkerIcon}
										color="red"
									/>
								))}
						</MarkerClusterGroup>
					)}
					{showOther && (
						<MarkerClusterGroup
							showCoverageOnHover={false}
							iconCreateFunction={otherCluster}
						>
							{filteredMarkers
								.filter(
									(marker) => marker.event_type === "Other"
								)
								.map((data, index) => (
									<CustomMarker
										key={index}
										position={[data.lat, data.lng]}
										customMarkerIcon={customMarkerIcon}
										color="grey"
									/>
								))}
						</MarkerClusterGroup>
					)}
				</>
			)}

			{addNewEvent && (
				<LocationMarker
					newPosition={newPosition}
					setNewPosition={setNewPosition}
				/>
			)}
		</MapContainer>
	);
}

export default MapLayout;
