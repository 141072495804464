import React from "react";
import user_icon from "../../assets/person.png";
import email_icon from "../../assets/email.png";
import password_icon from "../../assets/password.png";
import logo from "../../assets/logo.png";
import { Link } from "react-router-dom";

function Register({ onRegister, onRegisterChange, userInput, error, success }) {
	return (
		<>
			<form onSubmit={(e) => onRegister(e)}>
				<div className="register-wrapper">
					<div className="auth-header">
						<div className="text">
							<h2>Sign Up</h2>
						</div>
						{/* <img src={logo} alt="saveyourhood-logo" /> */}
					</div>
					<div className="auth-inputs">
						<div className="input">
							<img src={user_icon} alt="" htmlFor="username" />

							<input
								onChange={(e) => onRegisterChange(e)}
								type="username"
								className="form-control"
								id="username"
								name="username"
								value={userInput.username}
								placeholder="username"
								required
							/>
						</div>
						<div className="input">
							<img src={email_icon} alt="" />
							<input
								onChange={(e) => onRegisterChange(e)}
								type="email"
								className="form-control"
								id="email"
								name="email"
								value={userInput.email}
								placeholder="email"
								required
							/>
						</div>
						<div className="input">
							<img src={password_icon} alt="" />
							<input
								onChange={(e) => onRegisterChange(e)}
								type="password"
								value={userInput.password}
								className="form-control"
								id="password"
								name="password"
								placeholder="passwod"
								required
							/>
						</div>
					</div>
					<div style={{ color: "red", margin: "10px 0" }}>
						{error}
					</div>
					<div style={{ color: "green", margin: "10px 0" }}>
						{success}
					</div>
					<div className="submit-container">
						<button type="submit" className="submit-btn">
							Sign Up
						</button>
					</div>
					<div className="bottom-auth-form">
						<div className="ottom-auth-form-text">
							Already have an account?
						</div>
						<Link className="bottom-auth-form-text-ref" to="/login">
							Sign in here.
						</Link>
						{/* <div
							className="bottom-auth-form-text-ref"
							onClick={() => {
								setError(false);
								setSuccess(false);
							}}
						>
							Sign in here.
						</div> */}
					</div>
				</div>
			</form>
		</>
	);
}

export default Register;
