import React, { useState } from "react";
import { TextField } from "@mui/material";
import { IoMdSend } from "react-icons/io";
import { InputAdornment } from "@mui/material";
const CommentForm = ({ handleSubmit, initialText = "" }) => {
	const [text, setText] = useState(initialText);
	const onSubmit = (event) => {
		// event.preventDefault();
		handleSubmit(text);
		setText("");
	};

	return (
		<div style={{ position: "sticky", bottom: "0" }}>
			<TextField
				placeholder="Message here ... "
				fullWidth
				style={{ borderRadius: 999 }}
				value={text}
				onChange={(e) => setText(e.target.value)}
				onKeyDown={(event) => {
					if (
						event.key === "Enter" &&
						!event.shiftKey &&
						!event.ctrlKey &&
						!event.altKey &&
						!event.metaKey
					) {
						onSubmit();
					}
				}}
				InputProps={{
					endAdornment: (
						<InputAdornment
							position="end"
							className="hover:cursor-pointer hover:text-blue-500"
						>
							<IoMdSend onClick={onSubmit} />
						</InputAdornment>
					),
				}}
			/>
		</div>
	);
};

export default CommentForm;
