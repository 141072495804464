import React, { useEffect } from "react";
import { onVerify } from "../api/auth";
import { useParams, useNavigate } from "react-router-dom";
function Verify() {
	const { a, b } = useParams();
	const navigate = useNavigate();
	useEffect(() => {
		onVerify({ a: a, b: b })
			.then((res) => {
				navigate("/login", { state: { fromverify: true } });
			})
			.catch((error) => {
				navigate("/");
			});
	}, []);

	return <div></div>;
}

export default Verify;
