import React, { useRef, useEffect, useState } from "react";
import {
	BrowserRouter,
	Route,
	Routes,
	Navigate,
	Outlet,
} from "react-router-dom";
import Home from "./pages/Home";
import Initiative from "./pages/Initiative";
import RegisterPage from "./pages/RegisterPage";
import LoginPage from "./pages/LoginPage";
import NavigationBar from "./components/NavigationBar";
import Dashboard from "./pages/Dashboard";
import { useSelector } from "react-redux";
import Verify from "./pages/Verify";
const PrivateRoutes = () => {
	const authState = useSelector((state) => state.auth);

	return <>{authState.isAuth ? <Outlet /> : <Navigate to="/" />}</>;
};

const RestrictedRoutes = () => {
	const authState = useSelector((state) => state.auth);

	return <>{!authState.isAuth ? <Outlet /> : <Navigate to="/" />}</>;
};

function App() {
	return (
		<BrowserRouter>
			<NavigationBar />
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/initiative/:eventid" element={<Initiative />} />
				<Route element={<PrivateRoutes />}>
					<Route path="/dashboard" element={<Dashboard />} />
				</Route>
				<Route element={<RestrictedRoutes />}>
					<Route path="/login" element={<LoginPage />} />
				</Route>
				<Route element={<RestrictedRoutes />}>
					<Route path="/register" element={<RegisterPage />} />
				</Route>
				<Route element={<RestrictedRoutes />}>
					<Route path="/verify/:a/:b" element={<Verify />} />
				</Route>
			</Routes>
		</BrowserRouter>
	);
}

export default App;
