import React, { useEffect, useState } from "react";
import { onRegistration } from "../api/auth";
import Register from "../components/AuthPage/Register";
import Bubbles from "../components/AuthPage/Bubbles";
import "../css/LoginSignup.css";

function RegisterPage() {
	const [userInput, setUserInput] = useState({
		username: "",
		email: "",
		password: "",
	});
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	const onRegisterChange = (e) => {
		setError(false);
		setSuccess(false);
		setUserInput({ ...userInput, [e.target.name]: e.target.value });
	};

	const onRegister = async (e) => {
		e.preventDefault();

		try {
			const response = await onRegistration(userInput);
			setError("");
			setSuccess(response.data.message);
			setUserInput({ username: "", email: "", password: "" });
		} catch (error) {
			setSuccess("");
			setError(error.response.data.errors[0].msg);
		}
	};

	return (
		<div className="container">
			<div className="form-container">
				<Register
					onRegister={onRegister}
					onRegisterChange={onRegisterChange}
					userInput={userInput}
					error={error}
					success={success}
				/>
			</div>
			<Bubbles />
		</div>
	);
}

export default RegisterPage;
