import React from "react";
import "../../css/UsersStats.css";
function UserStats({ approved, pending }) {
	return (
		<div className="user-stats-container">
			<div className="user-card">
				<h2>Pending</h2>
				<p>Total: {pending.length}</p>
			</div>
			<div className="user-card">
				<h2>Approved</h2>
				<p>Total: {approved.length}</p>
			</div>
		</div>
	);
}

export default UserStats;
