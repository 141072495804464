import React, { useEffect, useState, useRef } from "react";
import "../css/chat.css";
import CommentForm from "./CommentForm";
import Comment from "./Comment";

import { io } from "socket.io-client";

function Chat({ eventid }) {
	const [socket, setSocket] = useState(null);
	const [messagesRecieved, setMessagesReceived] = useState([]);
	const messagesColumnRef = useRef(null); // Add this
	const addComment = (message) => {
		if (message === "") return;
		const __createdtime__ = Date.now();
		socket.emit("send_message", {
			eventid,
			message,
			__createdtime__,
		});
	};

	useEffect(() => {
		const newSocket = io(
			process.env.NODE_ENV === "development"
				? `http://localhost:5000`
				: `https://projectkapa.gr/`,
			{
				path: "/sockjs-node",
				withCredentials: true,
			}
		);
		setSocket(newSocket);
		return () => {
			newSocket.disconnect();
			newSocket.close();
		};
	}, []);

	useEffect(() => {
		if (socket === null) return;
		socket.emit("join_messages", { eventid });
		socket.on("getOnlineUsers", (res) => {
			// console.log(res);
		});
		return () => {
			socket.off("getOnlineUsers");
		};
	}, [socket]);
	useEffect(() => {
		if (socket === null) return;
		socket.on("receive_message", (data) => {
			console.log(data);
			setMessagesReceived((state) => [
				...state,
				{
					message: data.message,
					username: data.username,
					__createdtime__: data.__createdtime__,
				},
			]);
		});

		return () => socket.off("receive_message");
	}, [socket]);
	function sortMessagesByDate(messages) {
		return messages.sort(
			(a, b) => parseInt(a.__createdtime__) - parseInt(b.__createdtime__)
		);
	}
	useEffect(() => {
		if (socket === null) return;

		socket.on("last_100_messages", (last100Messages) => {
			console.log(last100Messages);
			last100Messages = sortMessagesByDate(last100Messages);
			setMessagesReceived((state) => [...last100Messages, ...state]);
		});

		return () => socket.off("last_100_messages");
	}, [socket]);
	useEffect(() => {
		messagesColumnRef.current.scrollTop =
			messagesColumnRef.current.scrollHeight;
	}, [messagesRecieved]);
	return (
		<div className="map-layout--content ">
			<div
				className="comments-container"
				// style={{
				// 	overflow: "auto",
				// 	height: "calc(100vh - 305px)", //235 htan
				// 	wordBreak: "break-word",
				// }}
				ref={messagesColumnRef}
			>
				{messagesRecieved.map((msg, id) => {
					return <Comment key={id} msg={msg} />;
				})}
				{/* {rootComments.map((rootComment) => (
					
				))} */}
			</div>

			<CommentForm handleSubmit={addComment} />
		</div>
	);
}

export default Chat;
